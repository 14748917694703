import { useQueries, type UseQueryOptions } from '@tanstack/react-query';
import { cloneDeep, get } from 'lodash';
import { useMemo } from 'react';
import {
  identifierMapping,
  type Identifier,
  type IdentifierInfo,
} from './data';
import type { StatisticsSystemEvents } from './types';
import {
  buildQueryForStatisticsSystemEvents,
  getDateRange,
  type DateCategory,
  type DateRange,
} from './utils';

export const useGetStatisticsSystemEvents = (
  options: Omit<
    UseQueryOptions<StatisticsSystemEvents>,
    'queryKey' | 'queryFn'
  > & {
    identifierString: string;
    range: DateRange | undefined;
    dateCategory: DateCategory | undefined;
  }
) => {
  const { identifierString, range, dateCategory, ...queryOptions } =
    options || {};
  const identifiers = identifierString?.split(',') as Identifier[];

  const batchedQueries = useMemo(() => {
    const requests = [];
    for (const identifier of identifiers) {
      const {
        modelName,
        filters,
        content_type: contentType,
        noFilterReturn,
        statistics_options: statisticsOptions,
        timeline_attributes: timelineAttributes,
      } = get(identifierMapping, identifier, {}) as IdentifierInfo;
      const clonedFilters = cloneDeep(filters) ?? {};
      if (range && dateCategory && timelineAttributes && modelName) {
        for (const timelineAttribute of timelineAttributes[dateCategory] ??
          []) {
          if (statisticsOptions?.includes('debt_timeline')) {
            clonedFilters[timelineAttribute] = {
              __lt:
                getDateRange(range, dateCategory)?.endDate.toISODate() ??
                undefined,
            };
          } else {
            clonedFilters[timelineAttribute] = {
              __gte:
                getDateRange(range, dateCategory)?.startDate.toISODate() ??
                undefined,
              __lte:
                getDateRange(range, dateCategory)?.endDate.toISODate() ??
                undefined,
            };
          }
        }
        if (timelineAttributes[dateCategory]) {
          requests.push(
            buildQueryForStatisticsSystemEvents({
              modelName,
              identifier,
              contentType,
              filters: clonedFilters,
              noFilterReturn,
              statisticsOptions,
              ...queryOptions,
            })
          );
        }
      }
    }

    return requests;
  }, [dateCategory, identifiers, queryOptions, range]);

  const mainQueries = useQueries({
    queries: batchedQueries,
    combine: (results) => {
      return {
        data: results
          .flatMap((result) => result.data)
          .filter((res) => res !== undefined),
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        isFetching: results.some((result) => result.isFetching),
        isPending: results.some((result) => result.isPending),
        isRefetching: results.some((result) => result.isRefetching),
        isStale: results.every((result) => result.isStale),
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });
  return {
    ...mainQueries,
  };
};
