import { Icons } from '@/components/icons';
import { getRedirectUrl } from '@/components/sidebar/components/floating-menu/components/notifications/utils';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useGetWorkEngagement } from '@/config/accounts/users/organization/workengagement/client';
import { useMutateNotification } from '@/config/communication/notification/mutate';
import type { JsonFilter } from '@/hooks/use-table-filter/types';
import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import { useGetSelf } from '@/hooks/useGetSelf';
import { cn } from '@/lib/cn';
import { toReadableDateTime } from '@/lib/dates';
import { fetchAllInstances } from '@/requests/api/fetch-all-instances';
import { getBytesOfString } from '@/requests/api/utils';
import { MAX_BYTES_IN_QUERY_PARAMETERS } from '@/requests/constants';
import { BellIcon, EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import {
  ContentTypeToName,
  NotificationConfig,
  OrganizationUserWorkEngagementConfig,
  type BaseInstance,
  type ContentType,
  type ModelName,
  type Notification,
} from '@pigello/pigello-matrix';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import type { DashBoard, WorkEngagementDashboard } from '../types';
import { FacetedFilter } from './filter-checkbox';

const getSelectedInstanceIdArrays = <
  Instance extends BaseInstance = BaseInstance,
>(
  modelName: ModelName,
  identifiers?: string[],
  filters?: JsonFilter
) => {
  const parsedFilters = parseJsonFilter(filters ?? {});
  const bytesInFilters = getBytesOfString(parsedFilters);
  const totalBytesInIdFilter = getBytesOfString(identifiers?.join(',') ?? '');
  const canFetchAllInOnePage =
    totalBytesInIdFilter + bytesInFilters <= MAX_BYTES_IN_QUERY_PARAMETERS;

  // if we cant fit all ids in the byte limit, we need to split it in half and try again until we can
  if (!canFetchAllInOnePage && identifiers) {
    const multipleValues = [
      identifiers.slice(0, Math.floor(identifiers.length / 2)),
      identifiers.slice(Math.floor(identifiers.length / 2)),
    ];
    let promises: ReturnType<typeof fetchAllInstances<Instance>>[] = [];

    for (const valueArr of multipleValues) {
      const result = getSelectedInstanceIdArrays<Instance>(modelName, valueArr);

      promises = [...promises, ...result];
    }
    return promises;
  } else {
    // here we're under the byte limit, return a promise
    return [
      fetchAllInstances<Instance>({
        filters: {
          ...(identifiers && !filters?.event_identifier
            ? {
                event_identifier: {
                  __icontains: identifiers.join(','),
                },
              }
            : {}),
          ...filters,
        },
        modelName: modelName,
        order: ['-createdAt'],
      }),
    ];
  }
};

const fetchAllNotifications = async <
  Instance extends BaseInstance = BaseInstance,
>({
  modelName,
  filters,
  eventIdentifiers,
}: {
  modelName: ModelName;
  eventIdentifiers?: string[];
  filters?: JsonFilter;
}): Promise<Instance[]> => {
  const allInstancePromises = getSelectedInstanceIdArrays<Instance>(
    modelName,
    eventIdentifiers,
    filters
  );

  const instanceResult = await Promise.all(allInstancePromises);

  return instanceResult.flatMap((r) => r.list);
};

const useFetchAllNotifications = ({
  modelName,
  filters,
  eventIdentifiers,
  enabled,
}: {
  modelName: ModelName;
  eventIdentifiers: string[];
  filters?: JsonFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['notifications-all', modelName, eventIdentifiers, filters],
    queryFn: async () => {
      const res = await fetchAllNotifications<Notification>({
        modelName,
        filters,
        eventIdentifiers,
      });
      return res;
    },
    staleTime: 60 * 5 * 1000,
    refetchOnWindowFocus: true,
    enabled,
  });
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
};

const nonClickableNotificationEvents = [
  'accounts.organizationuser.userhotploginlinkcreated',
];
export function DashboardNotifications({
  dashboards,
  dashboardKey,
}: {
  dashboards: (DashBoard | WorkEngagementDashboard)[] | undefined;
  dashboardKey: string | undefined;
}) {
  const { data: self } = useGetSelf();
  const [selected, setSelected] = useState<string[]>();
  const dashboard = dashboards?.find((d) => d.key === dashboardKey);
  const workEngagementId =
    dashboard && 'work_engagement_id' in dashboard
      ? dashboard.work_engagement_id
      : undefined;

  const { data: workEngagement } = useGetWorkEngagement({
    id: workEngagementId as string,
    enabled: !!workEngagementId && !!dashboard?.key,
  });

  const workEngagementNotifications: string[] = useMemo(
    () => workEngagement?.templateNotifications ?? [],
    [workEngagement?.templateNotifications]
  );
  const groupedNotifications = useMemo(() => {
    const notificationContentTypes: { v: string; d: string }[] =
      OrganizationUserWorkEngagementConfig.fields.receiveNotificationsFor
        .choices;
    const grouped = Object.groupBy(
      notificationContentTypes.filter((notification) => {
        if (workEngagementNotifications.length === 0) return true;
        return workEngagementNotifications.includes(notification.v);
      }),
      (notification) =>
        notification.v.split('.').slice(0, 2).join('.') ?? notification.v
    );
    return grouped;
  }, [workEngagementNotifications]);

  const { data: notifications, isLoading: isLoadingNotifications } =
    useFetchAllNotifications({
      modelName: NotificationConfig.modelName,
      eventIdentifiers: selected ?? workEngagementNotifications,
      filters: {
        sent_to_organization_user: { noop: self?.id },
        ...(selected && !workEngagementNotifications
          ? {
              event_identifier: {
                __icontains: selected.join(','),
              },
            }
          : {}),
      },
      enabled: !!dashboard?.key,
    });
  return (
    <Card className='gap-0 p-0'>
      <CardHeader className='grid gap-2 border-b-0 p-0 px-4 pt-4'>
        <div className='flex items-center justify-between'>
          <CardTitle>Notiser</CardTitle>

          <FacetedFilter
            selected={selected}
            setSelected={setSelected}
            options={Object.keys(groupedNotifications)?.map((notification) => ({
              label: ContentTypeToName[notification as ContentType],
              value: notification,
            }))}
            disabled={!dashboardKey}
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className='relative flex h-80 flex-col overflow-y-auto py-2'>
          {!!notifications?.length && (
            <motion.div variants={container} initial='hidden' animate='show'>
              {notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </motion.div>
          )}
          {!notifications?.length && !isLoadingNotifications && (
            <div className='flex h-full flex-col items-center justify-center gap-0.5 text-center'>
              <BellIcon className='size-6' />
              <span className='text-sm font-medium'>Inga notiser</span>
              <span className='px-4 text-xs text-muted-foreground'>
                {!dashboardKey
                  ? 'Välj eller skapa en dashboard för att se notiser'
                  : 'Inga notiser hittades.'}
              </span>
            </div>
          )}
          {isLoadingNotifications && (
            <div className='flex size-full items-center justify-center'>
              <div className='my-4 flex h-24 flex-col items-center justify-center gap-2'>
                <Icons.loader className='size-4 animate-spin' />
                <span className='text-xs font-medium text-muted-foreground'>
                  Hämtar notiser...
                </span>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

const item = {
  hidden: { opacity: 0, y: -5 },
  show: { opacity: 1, y: 0 },
};

function NotificationItem({ notification }: { notification: Notification }) {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const res = await getRedirectUrl(notification.id);
      return { redirectUrl: res.redirectUrl };
    },
    onSuccess: ({ redirectUrl }) => {
      markAsRead({
        id: notification.id,
        body: { read: true },
      });
      if (redirectUrl) {
        router.push(redirectUrl);
      }
    },
    onError: () => {
      toast.error('Misslyckades med att hämta aviseringen');
    },
  });
  const { mutate: markAsRead, isPending: isMarkingAsRead } =
    useMutateNotification({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications-all'],
        });
      },
      onError: () => {
        toast.error('Misslyckades med att markera som läst');
      },
    });
  const hasNonClickableNotification =
    !!notification.eventIdentifier &&
    nonClickableNotificationEvents.includes(notification.eventIdentifier);
  return (
    <motion.div
      variants={item}
      tabIndex={0}
      key={notification.id}
      role='button'
      onKeyDown={(e) => {
        if (
          e.key === 'Enter' &&
          hasNonClickableNotification &&
          (!isPending || !isMarkingAsRead)
        ) {
          markAsRead({
            id: notification.id,
            body: { read: true },
          });
          return;
        }
        if (
          e.key === 'Enter' &&
          !hasNonClickableNotification &&
          (!isPending || !isMarkingAsRead)
        ) {
          mutate();
        }
      }}
      onClick={() => {
        if (isPending || isMarkingAsRead) return;
        if (hasNonClickableNotification) {
          markAsRead({
            id: notification.id,
            body: { read: true },
          });
          return;
        }
        mutate();
      }}
      className='px-2'
    >
      <div
        className={cn(
          'group flex items-center gap-2 rounded-md p-2 text-left transition-colors hover:bg-muted',
          notification.read && 'opacity-70'
        )}
      >
        <div className='flex w-full items-center justify-between gap-2'>
          <div className='flex flex-1 flex-col gap-0.5'>
            <p className='text-xs font-medium'>{notification.title}</p>
            <p className='text-xs'>
              {notification.content.length > 50 && !expanded
                ? `${notification.content.substring(0, 50)}...`
                : notification.content}{' '}
              {notification.content.length > 50 && (
                <Button
                  size='sm'
                  variant='link'
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpanded((prev) => !prev);
                  }}
                  className='h-auto whitespace-nowrap p-0 text-xs'
                >
                  {expanded ? ' Visa mindre' : ' Visa mer'}
                </Button>
              )}
            </p>
            <p className='w-full text-xs text-muted-foreground'>
              {toReadableDateTime(notification.createdAt)}
            </p>
          </div>

          <div className='flex items-center gap-2'>
            <DropdownMenu open={open} onOpenChange={setOpen}>
              <DropdownMenuTrigger asChild>
                <Button
                  className='invisible rounded-full focus-within:visible group-hover:visible data-[state=open]:visible'
                  size='icon'
                  variant='ghost'
                >
                  <EllipsisHorizontalIcon className='size-4' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end'>
                <DropdownMenuItem
                  onClick={(e) => {
                    markAsRead({
                      id: notification.id,
                      body: { read: !notification.read },
                    });
                    setOpen(false);
                    e.stopPropagation();
                  }}
                >
                  Markera som {notification.read ? 'oläst' : 'läst'}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            {!notification.read && (
              <div className='size-2.5 rounded-full bg-primary' />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
