import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import OverlayLoader from '@/components/ui/overlay-loader';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetCookies } from '@/hooks/use-get-cookies';
import { cn } from '@/lib/cn';
import {
  CheckIcon,
  ChevronUpDownIcon,
  PencilIcon,
  PlusIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import type {
  OrganizationUser,
  OrganizationUserWorkEngagement,
} from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { toast } from 'sonner';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type { DashBoard, WorkEngagementDashboard } from '../types';
import { CreateDashboardModal } from './create-dashboard-modal';
import { DeleteDashboardModal } from './delete-dashboard-modal';
import { UpdateDashboardModal } from './update-dashboard-modal';

interface Props {
  dashboardKey: string | undefined;
  setDashboardKey: Dispatch<SetStateAction<string | undefined>>;
  workEngagementsDashboards: WorkEngagementDashboard[] | undefined;
  dashboards: DashBoard[] | undefined;
  isLoadingDashboards: boolean;
  isWorkEngagementDashboard: boolean;
  organizationUserWorkEngagements: OrganizationUserWorkEngagement[] | undefined;
  createdByOrganizationUsers: OrganizationUser[] | undefined;
}

export function HandleDashboardModal({
  dashboards,
  workEngagementsDashboards,
  dashboardKey,
  setDashboardKey,
  isLoadingDashboards,
  isWorkEngagementDashboard,
  organizationUserWorkEngagements,
  createdByOrganizationUsers,
}: Props) {
  const { cookies } = useGetCookies();
  const queryClient = useQueryClient();
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteKey, setDeleteKey] = useState<string | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { mutateAsync: mutateDashboard, isPending: isUpdatingDashboard } =
    useUpdateDashboard();

  const { mutate: mutateFavoriteDashboard, isPending: isUpdating } =
    useUpdateDashboard({
      onSuccess: (data) => {
        const previousRankingDashboard = dashboards?.find(
          (dashboard) => dashboard.ranking === 0 && dashboard.key !== data.key
        );
        if (!previousRankingDashboard) {
          return;
        }
        previousRankingDashboard.ranking = null;
        toast.promise(mutateDashboard(previousRankingDashboard), {
          loading: 'Sparar...',
          success: () => {
            queryClient.invalidateQueries({
              queryKey: [DASHBOARD_QUERY_KEY],
            });
            return 'Sparad';
          },
          error: () => {
            return 'Misslyckades med att spara';
          },
        });
      },
    });

  const allDashboards = [
    ...(dashboards ?? []),
    ...(workEngagementsDashboards ?? []),
  ];

  const currentDashboard = allDashboards?.find(
    (dashboard) => dashboardKey === dashboard.key
  );

  const favoriteDashboard = (dashboardKey: string) => {
    const currentRankingDashboard = dashboards?.find(
      (dashboard) => dashboard.key === dashboardKey
    );
    if (!currentRankingDashboard) {
      toast.error('Kunde inte hitta dashboard');
      return;
    }
    currentRankingDashboard.ranking === 0
      ? (currentRankingDashboard.ranking = null)
      : (currentRankingDashboard.ranking = 0);

    mutateFavoriteDashboard(currentRankingDashboard);
  };

  return (
    <>
      {createOpen && (
        <CreateDashboardModal
          closeFunction={() => setCreateOpen(false)}
          setDashboardKey={setDashboardKey}
          currentDashboard={currentDashboard}
        />
      )}
      {editOpen && (
        <UpdateDashboardModal
          closeFunction={() => setEditOpen(false)}
          currentDashboard={currentDashboard}
        />
      )}
      {deleteKey && (
        <DeleteDashboardModal
          dashboardKey={deleteKey}
          closeFunction={() => {
            setDeleteKey(null);
            setDashboardKey(undefined);
          }}
        />
      )}

      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={popoverOpen}
            className='h-10 w-52 justify-between'
          >
            <div className='line-clamp-1 h-4 max-w-52 text-left'>
              {isLoadingDashboards || !cookies?.customer_id
                ? 'Laddar...'
                : dashboardKey
                  ? allDashboards?.find(
                      (dashboard) => dashboard.key === dashboardKey
                    )?.name
                  : 'Välj dashboard...'}
            </div>
            <ChevronUpDownIcon className='ml-2 size-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='relative w-auto min-w-72 p-0' align='end'>
          <Command shouldFilter={false}>
            <CommandList>
              <CommandGroup>
                <div className='flex gap-2 whitespace-nowrap'>
                  <CommandItem
                    onSelect={() => {
                      setPopoverOpen(false);
                      setCreateOpen(true);
                    }}
                  >
                    <PlusIcon className='mr-2 size-4' /> Skapa ny
                  </CommandItem>
                  <CommandItem
                    disabled={!dashboardKey || isWorkEngagementDashboard}
                    onSelect={() => {
                      setPopoverOpen(false);
                      setEditOpen(true);
                    }}
                    aria-disabled={!dashboardKey || isWorkEngagementDashboard}
                    className='disabled:opacity-50 aria-disabled:opacity-50'
                  >
                    <PencilIcon className='mr-2 size-4' /> Redigera
                  </CommandItem>
                  <CommandItem
                    disabled={
                      !dashboardKey ||
                      isWorkEngagementDashboard ||
                      isUpdatingDashboard
                    }
                    onSelect={() => {
                      setPopoverOpen(false);
                      if (dashboardKey) {
                        setDeleteKey(dashboardKey);
                      }
                    }}
                    className='disabled:opacity-50 aria-disabled:opacity-50'
                  >
                    <TrashIcon className='mr-2 size-4' /> Radera
                  </CommandItem>
                </div>
              </CommandGroup>
              <CommandSeparator />
            </CommandList>
            <CommandInput
              placeholder='Sök...'
              onValueChange={(value) => setSearch(value)}
            />
            {isUpdating && <OverlayLoader message='Uppdaterar...' />}
            {!!dashboards?.length && (
              <CommandList>
                <CommandEmpty>Inga dashboards hittades.</CommandEmpty>
                <CommandGroup key={'Användare'} heading='Dina dashboards'>
                  {dashboards
                    .filter((dashboard) =>
                      dashboard.name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    // Sort favorites first
                    ?.sort((a, b) =>
                      a.ranking === null && b.ranking === null
                        ? 0
                        : a.ranking === null
                          ? 1
                          : b.ranking === null
                            ? -1
                            : a.ranking > b.ranking
                              ? 1
                              : -1
                    )
                    .map((dashboard) => (
                      <div
                        key={dashboard.key}
                        className='flex items-center justify-between gap-2'
                      >
                        <CommandItem
                          value={dashboard.key}
                          onSelect={(currentValue) => {
                            setDashboardKey(currentValue);
                            setPopoverOpen(false);
                          }}
                          className='w-full'
                        >
                          <CheckIcon
                            className={cn(
                              'mr-2 size-4',
                              dashboardKey === dashboard.key
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                          {dashboard.name}
                        </CommandItem>
                        <Tooltip delayDuration={100}>
                          <TooltipTrigger
                            asChild
                            disabled={isUpdatingDashboard}
                          >
                            <Button
                              variant={'ghost'}
                              size={'icon-sm'}
                              onClick={() => favoriteDashboard(dashboard.key)}
                            >
                              {dashboard?.ranking === null ? (
                                <StarIconOutline className='size-4' />
                              ) : (
                                <StarIcon className='size-4' />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent className='font-normal' align='end'>
                            {dashboard?.ranking === null
                              ? 'Markera som favorit'
                              : 'Ta bort favorit'}
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    ))}
                </CommandGroup>
                <CommandSeparator />
              </CommandList>
            )}
            {!!workEngagementsDashboards?.length && (
              <>
                <CommandList>
                  <CommandGroup
                    key={'Arbetsuppgifter'}
                    heading='Dashboards kopplade till dina arbetsuppgifter'
                  >
                    {workEngagementsDashboards
                      ?.filter((dashboard) =>
                        dashboard.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                      .map((workEngagementsDashboard) => (
                        <CommandItem
                          key={workEngagementsDashboard.key}
                          value={workEngagementsDashboard.key}
                          onSelect={(currentValue) => {
                            setDashboardKey(currentValue);
                            setPopoverOpen(false);
                          }}
                        >
                          <CheckIcon
                            className={cn(
                              'mr-2 size-4',
                              dashboardKey === workEngagementsDashboard.key
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                          <div className='flex flex-col items-start'>
                            <span>{workEngagementsDashboard.name}</span>
                            <span className='text-xs text-muted-foreground'>
                              {
                                organizationUserWorkEngagements?.find(
                                  (we) =>
                                    we.workEngagement?.id ===
                                    workEngagementsDashboard?.work_engagement_id
                                )?.workEngagement?.name
                              }{' '}
                              - Skapad av{' '}
                              {createdByOrganizationUsers?.find(
                                (ou) =>
                                  ou.id ===
                                  workEngagementsDashboard.created_by_user_id
                              )?.communicationName ?? 'Okänd'}
                            </span>
                          </div>
                        </CommandItem>
                      ))}
                  </CommandGroup>
                </CommandList>
              </>
            )}
            <CommandSeparator />
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
