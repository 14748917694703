import { useQueries, type UseQueryOptions } from '@tanstack/react-query';
import { get } from 'lodash';
import { useMemo } from 'react';
import {
  identifierMapping,
  type Identifier,
  type IdentifierInfo,
} from './data';
import type { StatisticsToHandle } from './types';
import { buildQueryForStatistics } from './utils';

export const useGetStatisticsToHandle = (
  options: Omit<UseQueryOptions<StatisticsToHandle>, 'queryKey' | 'queryFn'> & {
    identifierString: string;
  }
) => {
  const { identifierString, ...queryOptions } = options || {};
  const identifiers = identifierString?.split(',') as Identifier[];

  const batchedQueries = useMemo(() => {
    const requests = [];
    for (const identifier of identifiers) {
      const {
        modelName,
        filters,
        content_type: contentType,
        noFilterReturn,
        group_modelNames: groupModelNames,
        group_content_types: groupContentType,
      } = get(identifierMapping, identifier, {}) as IdentifierInfo;
      if (groupModelNames) {
        for (const groupModelName of groupModelNames) {
          requests.push(
            buildQueryForStatistics({
              modelName: groupModelName,
              identifier,
              contentType: groupContentType,
              filters,
              noFilterReturn,
              ...queryOptions,
            })
          );
        }
        continue;
      }
      if (modelName) {
        requests.push(
          buildQueryForStatistics({
            modelName,
            identifier,
            contentType: groupContentType || contentType,
            filters,
            noFilterReturn,
            ...queryOptions,
          })
        );
      }
    }
    return requests;
  }, [identifiers, queryOptions]);

  const mainQueries = useQueries({
    queries: batchedQueries,
    combine: (results) => {
      return {
        data: results
          .flatMap((result) => result.data)
          .filter((res) => res !== undefined),
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        isFetching: results.some((result) => result.isFetching),
        isPending: results.some((result) => result.isPending),
        isRefetching: results.some((result) => result.isRefetching),
        isStale: results.every((result) => result.isStale),
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });
  return {
    ...mainQueries,
  };
};
