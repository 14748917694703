'use client';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetSelf } from '@/hooks/useGetSelf';
import { cn } from '@/lib/cn';
import {
  ArchiveBoxIcon,
  EllipsisVerticalIcon,
  FlagIcon,
  FunnelIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { toast } from 'sonner';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type { DashBoard, WorkEngagementDashboard } from '../types';
import { DashboardNotesModal } from './dashboard-notes-modal';
import { FacetedFilter } from './filter-checkbox';
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
};
const item = {
  hidden: { opacity: 0, y: -5 },
  show: { opacity: 1, y: 0 },
};

const options = [
  { value: 'pinned', label: 'Pinnade' },
  { value: 'flag', label: 'Flaggade' },
  { value: 'archived', label: 'Arkiverade' },
];

export function DashboardNotes({
  dashboards,
  dashboardKey,
  isLoadingDashboards,
}: {
  dashboards: (DashBoard | WorkEngagementDashboard)[] | undefined;
  dashboardKey: string | undefined;
  isLoadingDashboards: boolean;
}) {
  const dashboard = dashboards?.find((d) => d.key === dashboardKey);
  const notes = dashboard?.notes;
  const { data: self } = useGetSelf();
  const [noteIndex, setNoteIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState<string[]>();

  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateDashboard();
  const removeNote = (index: number) => {
    const clonedDashboard = cloneDeep(dashboard);
    const note = notes?.[index];
    if (!note || !clonedDashboard) {
      toast.error('Kunde ej ta bort anteckning');
      return;
    }
    clonedDashboard?.notes.splice(index, 1);
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Tar bort...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        return 'Anteckningen har tagits bort';
      },
      error: () => {
        return 'Anteckningen kunde ej tas bort';
      },
    });
  };
  const archiveNote = (index: number) => {
    const clonedDashboard = cloneDeep(dashboard);
    const note = notes?.[index];
    if (!note || !clonedDashboard) {
      toast.error('Kunde ej arkivera anteckning');
      return;
    }
    if (!note.archived) {
      clonedDashboard.notes[index].pinned = false;
      clonedDashboard.notes[index].color = null;
    }
    clonedDashboard.notes[index].archived = !note.archived;

    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Sparar...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        return 'Sparat!';
      },
      error: () => {
        return 'Kunde ej spara';
      },
    });
  };
  const pinNote = (index: number) => {
    const clonedDashboard = cloneDeep(dashboard);
    const note = notes?.[index];
    if (!note || !clonedDashboard) {
      toast.error('Kunde ej pinna anteckning');
      return;
    }
    if (!note.pinned) {
      note.pinned = true;
      clonedDashboard.notes.splice(index, 1);
      clonedDashboard.notes.unshift(note);
    } else {
      note.pinned = false;
      // find the note
      // Insert it at the correct position based on created_at
      // Keep the pinned notes at the top in the same order as they were
      clonedDashboard.notes.splice(index, 1);
      const pinnedNotes = clonedDashboard.notes?.filter((note) => note.pinned);
      const sortedNotes = [...clonedDashboard.notes, note]
        ?.filter((n) => !n.pinned)
        .toSorted(
          (a, b) =>
            DateTime.fromISO(a.created_at).toMillis() -
            DateTime.fromISO(b.created_at).toMillis()
        );
      clonedDashboard.notes = [...pinnedNotes, ...sortedNotes];
    }
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Sparar...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        return 'Sparat!';
      },
      error: () => {
        return 'Kunde ej spara';
      },
    });
  };
  const flagNote = (index: number) => {
    const clonedDashboard = cloneDeep(dashboard);
    const note = notes?.[index];
    if (!note || !clonedDashboard) {
      toast.error('Nånting gick fel');
      return;
    }
    if (note.color === null) {
      note.color = '#fff';
    } else {
      note.color = null;
    }
    clonedDashboard.notes[index] = note;
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Sparar...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        return 'Sparat!';
      },
      error: () => {
        return 'Kunde ej spara';
      },
    });
  };
  const selectedValues = new Set(filter);
  const filteredNotesLength =
    notes?.filter((note) => {
      if (selectedValues.size === 0) return true;
      if (selectedValues.has('pinned') && note.pinned) return true;
      if (selectedValues.has('flag') && note.color) return true;
      if (selectedValues.has('archived') && note.archived) return true;
      return false;
    }).length ?? 0;
  return (
    <>
      {dashboard && (
        <DashboardNotesModal
          dashboard={dashboard}
          noteIndex={noteIndex}
          setNoteIndex={setNoteIndex}
          open={open}
          setOpen={setOpen}
        />
      )}
      <Card className='h-full gap-0 p-0'>
        <CardHeader className='grid gap-2'>
          <div className='flex flex-row items-center justify-between px-4 pt-4 text-sm font-medium'>
            <div className='flex items-center gap-2'>
              <Avatar className='size-8'>
                <AvatarImage
                  src={self?.profilePicture?.get}
                  alt={self?.communicationName}
                />
                <AvatarFallback className='border bg-primary text-xs text-primary-foreground'>
                  {self?.firstName?.[0]}
                  {self?.lastName?.[0]}
                </AvatarFallback>
              </Avatar>
              <CardTitle className='flex items-center gap-2'>
                Mina anteckningar
              </CardTitle>
            </div>
            <div className='flex items-center gap-2.5'>
              <FacetedFilter
                options={options}
                selected={filter}
                setSelected={setFilter}
                disabled={!notes?.length}
              />
              {self && dashboardKey && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='outline'
                      size={'icon'}
                      onClick={() => setOpen(true)}
                    >
                      <PencilIcon className='size-4' />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className='font-normal'>
                    Skapa ny anteckning
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </div>
        </CardHeader>
        <CardContent className={cn(isLoadingDashboards && 'h-80 pb-4')}>
          {isLoadingDashboards &&
            Array(3)
              .fill(0)
              .map(() => (
                <motion.div
                  key={crypto.randomUUID()}
                  className='mx-4 my-2 flex items-start justify-between gap-2 rounded-md bg-muted p-4'
                >
                  <div className='flex items-start gap-2'>
                    <Skeleton className='size-6 rounded-full' />
                    <div className='flex flex-col gap-2'>
                      <Skeleton className='h-2 w-20' />
                      <Skeleton className='h-2 w-10' />
                    </div>
                  </div>
                  <Skeleton className='h-2 w-10' />
                </motion.div>
              ))}
          {!isLoadingDashboards && !notes?.length && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='flex h-72 flex-col items-center justify-center gap-0.5 text-center'
            >
              <PencilSquareIcon className='size-6' />
              <span className='text-sm font-medium'>Anteckningar</span>
              <span className='text-xs'>
                {!dashboardKey
                  ? 'Välj eller skapa en dashboard för att se dina anteckningar'
                  : 'Inga anteckningar tillagda.'}
              </span>
            </motion.div>
          )}
          {!isLoadingDashboards &&
            !!notes?.length &&
            filteredNotesLength === 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className='flex h-72 flex-col items-center justify-center gap-0.5 text-center'
              >
                <FunnelIcon className='size-6' />
                <span className='text-sm font-medium'>Inga anteckningar</span>
                <span className='text-xs'>
                  Inga anteckningar hittades med valda filter
                </span>
              </motion.div>
            )}
          {!isLoadingDashboards &&
            !!notes?.length &&
            filteredNotesLength > 0 && (
              <motion.div
                variants={container}
                initial='hidden'
                animate='show'
                className='flex h-72 w-full flex-col items-start gap-4 overflow-y-auto p-4'
              >
                {notes?.map((note, idx) => (
                  <motion.div
                    variants={item}
                    key={`${note.created_at}-${idx}`}
                    className={cn(
                      'hidden w-full rounded-md border bg-muted p-2',
                      note.pinned && 'border-secondary bg-secondary',
                      selectedValues.size >= 1 &&
                        selectedValues.has('pinned') &&
                        note.pinned &&
                        'grid',
                      selectedValues.size >= 1 &&
                        selectedValues.has('flag') &&
                        note.color &&
                        'grid',
                      selectedValues.size >= 1 &&
                        selectedValues.has('archived') &&
                        note.archived &&
                        'grid',
                      selectedValues.size === 0 && 'grid',
                      !selectedValues.has('archived') &&
                        note.archived &&
                        'hidden'
                    )}
                  >
                    <div className='flex w-full items-center justify-between'>
                      <div
                        className={cn(
                          'flex items-center gap-1 text-xs text-muted-foreground first-letter:uppercase',
                          note.archived && 'opacity-50'
                        )}
                      >
                        {DateTime.fromISO(note.created_at).toFormat(
                          'cccc d LLL HH:mm:ss'
                        )}
                        {note.color && (
                          <FlagIcon className='size-4 rounded-full text-destructive' />
                        )}
                      </div>
                      {self && (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              className='shrink-0 p-1 py-0.5'
                              variant='ghost'
                              size='icon-sm'
                            >
                              <EllipsisVerticalIcon className='size-4' />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align='end'>
                            {!note.archived && (
                              <DropdownMenuItem
                                onClick={() => {
                                  setNoteIndex(idx);
                                  setOpen(true);
                                }}
                              >
                                <PencilIcon className='mr-2' width={16} />
                                Redigera
                              </DropdownMenuItem>
                            )}
                            {!note.archived && (
                              <DropdownMenuItem
                                onClick={() => {
                                  pinNote(idx);
                                }}
                              >
                                {note.pinned ? (
                                  <LockOpenIcon className='mr-2' width={16} />
                                ) : (
                                  <LockClosedIcon className='mr-2' width={16} />
                                )}
                                {note.pinned ? 'Ta bort' : 'Lås till toppen'}
                              </DropdownMenuItem>
                            )}
                            {!note.archived && (
                              <DropdownMenuItem
                                onClick={() => {
                                  flagNote(idx);
                                }}
                              >
                                <FlagIcon className='mr-2' width={16} />
                                {note.color ? 'Ta bort flagga' : 'Flagga'}
                              </DropdownMenuItem>
                            )}

                            {!note.pinned && (
                              <DropdownMenuItem
                                onClick={() => {
                                  archiveNote(idx);
                                }}
                              >
                                <ArchiveBoxIcon className='mr-2' width={16} />
                                {note.archived ? 'Återställ' : 'Arkivera'}
                              </DropdownMenuItem>
                            )}
                            <DropdownMenuItem
                              className='text-red-500 focus:bg-red-100 focus:text-red-600'
                              onClick={() => removeNote(idx)}
                            >
                              <TrashIcon className='mr-2' width={16} />
                              Radera
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                    </div>
                    <div
                      className={cn(
                        'grid gap-0.5',
                        note.archived && 'opacity-50'
                      )}
                    >
                      <div className='flex items-center gap-1 text-xs font-medium'>
                        {note.title.length > 29
                          ? `${note.title.substring(0, 30)}...`
                          : note.title}
                      </div>
                      <p className='break-words text-xs'>
                        {note.note_content.length > 100 && !expanded
                          ? `${note.note_content.substring(0, 100)}...`
                          : note.note_content}
                        {note.note_content.length > 100 && (
                          <Button
                            size='sm'
                            variant='link'
                            onClick={() => setExpanded((prev) => !prev)}
                            className='ml-1 h-auto p-0 text-xs'
                          >
                            {expanded ? ' Visa mindre' : ' Visa mer'}
                          </Button>
                        )}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            )}
        </CardContent>
      </Card>
    </>
  );
}
