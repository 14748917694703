'use client';
import { Icons } from '@/components/icons';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Loader } from '@/components/ui/loader';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { getTabIcon } from '@/components/window-keeper/tab-icon';
import { cn } from '@/lib/cn';
import { getPerms } from '@/lib/perms';
import { ObjectKeys } from '@/lib/utils';
import { useGetStatisticsToHandle } from '@/requests/hooks/use-get-statistics-to-handle';
import {
  ArrowRightEndOnRectangleIcon,
  ExclamationTriangleIcon,
  NoSymbolIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { cloneDeep } from 'lodash';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { toHandleWidgetData } from '../data/to-handle';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type {
  DashBoard,
  Statistic,
  Widget,
  WorkEngagementDashboard,
} from '../types';
import {
  createUrl,
  extractIdentifiers,
  subCategoryTranslationMap,
} from '../utils';
import { EditTodoWidgetModal } from './edit-todo-widget-modal';

const filteredWidgetsOnPermissions = (
  widgets: Omit<Widget, 'modelNames'>[]
) => {
  return widgets.filter(async (widget) => {
    const modelNames = toHandleWidgetData.find(
      (d) => d.sub_category === widget.sub_category
    )?.modelNames;
    if (!modelNames) return false;
    for await (const modelName of modelNames) {
      const perms = await getPerms(modelName);
      return perms.canView;
    }
  });
};

export function ToHandleWidget({
  dashboards,
  dashboardKey,
  isWorkEngagementDashboard,
}: {
  dashboardKey: string | undefined;
  dashboards: (DashBoard | WorkEngagementDashboard)[] | undefined;
  isWorkEngagementDashboard: boolean;
}) {
  const dashboard = dashboards?.find((d) => d.key === dashboardKey);
  const queryClient = useQueryClient();
  const [editOpen, setEditOpen] = useState(false);
  const unFilteredWidgets = dashboard?.widgets.filter(
    (widget) => widget.category === 'to_handle'
  );
  const { mutateAsync: updateDashboard } = useUpdateDashboard();

  const widgets = useMemo(() => {
    if (!unFilteredWidgets) return [];
    return filteredWidgetsOnPermissions(unFilteredWidgets);
  }, [unFilteredWidgets]);

  const groupByCategory = Object.groupBy(
    widgets ?? [],
    (item) => item.category
  );
  const groupBySubCategory = Object.groupBy(
    widgets ?? [],
    (item) => item.sub_category
  );
  const identifiers = extractIdentifiers(groupByCategory);

  const { data, isLoading, isFetching, isError } = useGetStatisticsToHandle({
    identifierString: identifiers.join(','),
    enabled: !!dashboard?.key,
  });

  const totalCount = data?.reduce((acc, curr) => {
    if (curr.identifier) {
      return acc + (curr.count ?? 0);
    }
    return acc;
  }, 0);

  const onSave = (stat: Statistic) => {
    if (!dashboard) return;
    const dashboardClone = cloneDeep(dashboard);
    const updatedWidget = dashboardClone.widgets.map((widget) => {
      const updatedStatistic = widget.statistics.map((s) => {
        if (s.identifier === stat.identifier) {
          return {
            ...s,
            pinned: !stat.pinned,
          };
        }
        return s;
      });
      return {
        ...widget,
        statistics: updatedStatistic,
      };
    });
    dashboardClone.widgets = updatedWidget;
    toast.promise(updateDashboard(dashboardClone), {
      loading: 'Sparar...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        return 'Uppdaterades';
      },
      error: () => {
        return 'Misslyckades med att spara';
      },
    });
  };

  const pinnedData =
    widgets
      ?.map((widget) => widget.statistics.filter((stat) => stat.pinned))
      .flatMap((widget) => widget) ?? [];

  return (
    <>
      {editOpen && dashboard && (
        <EditTodoWidgetModal
          closeFunction={() => setEditOpen(false)}
          dashboard={dashboard}
        />
      )}
      <Card className='h-full gap-0 p-0'>
        <CardHeader className='flex flex-row items-center justify-between gap-2 border-b-0 px-4 pt-4 text-sm font-medium'>
          <CardTitle className='flex items-center gap-2'>
            <ExclamationTriangleIcon className='size-4' /> Att hantera (
            {isLoading ? '-' : (totalCount ?? 0)})
          </CardTitle>

          {!isWorkEngagementDashboard && dashboardKey && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='outline'
                  size='icon'
                  onClick={() => setEditOpen(true)}
                >
                  <PencilIcon className='size-4' />
                </Button>
              </TooltipTrigger>
              <TooltipContent className='font-normal'>Redigera</TooltipContent>
            </Tooltip>
          )}
        </CardHeader>
        <CardContent
          className={cn(
            'relative flex h-80 flex-col',
            isLoading && 'gap-4 overflow-hidden px-4 pb-4'
          )}
        >
          {isFetching && !isLoading && (
            <div className='absolute inset-0 z-50 my-0 flex h-full items-center justify-center bg-background/80'>
              <Loader message='Hämtar systemhändelser...' className='my-0' />
            </div>
          )}
          {!data?.length && !isFetching && !isError && (
            <div className='flex h-full flex-col items-center justify-center gap-0.5 text-center'>
              <PencilSquareIcon className='size-6' />
              <span className='text-sm font-medium'>Inga att hantera</span>
              <span className='text-xs text-muted-foreground'>
                {!dashboardKey
                  ? 'Välj eller skapa en dashboard för att se händelser att hantera'
                  : 'Inga händelser att hantera.'}
              </span>
            </div>
          )}
          {!isLoading && isError && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='flex h-full flex-col items-center justify-center gap-0.5 text-center'
            >
              <NoSymbolIcon className='size-6' />
              <span className='text-sm font-medium'>Något gick fel</span>
              <span className='text-xs'>
                Försök igen, kontakta Pigello om problemet kvarstår
              </span>
            </motion.div>
          )}
          {isLoading &&
            Object.keys(groupBySubCategory).map((subCategory) => (
              <Card className='gap-0 p-0' key={subCategory}>
                {Array.from({ length: 4 }).map((_, i) => (
                  <div
                    className='flex items-center justify-between border-b p-3 last:border-b-0'
                    key={`${subCategory}-${i}`}
                  >
                    <div className='flex items-center gap-2'>
                      <Skeleton className='size-4' />
                      <Skeleton className='h-4 w-32' />
                    </div>
                    <Skeleton className='size-4' />
                  </div>
                ))}
              </Card>
            ))}
          <div className='flex flex-1 flex-col gap-4 overflow-auto px-4 pb-4'>
            {!!pinnedData.length && !isLoading && (
              <Card className='gap-0 rounded-md border-none bg-gradient-to-r from-primary via-border to-primary/30 p-px shadow-[10px_-24px_10px_-17px_rgba(0,0,0,0.06),0px_2px_4px_2px_rgba(81,101,251,0.08)]'>
                <div className='rounded-[calc(0.5rem-3px)] bg-background'>
                  {pinnedData.map((stat) => {
                    const identifier = stat.identifier;
                    const pData = data
                      ?.filter((item) => item.identifier !== undefined)
                      .find((item) => item.identifier === identifier);

                    if (!pData) return null;
                    return (
                      <div
                        key={stat.identifier}
                        className='group flex w-full items-center justify-between gap-2 border-b p-2 text-sm font-medium transition-colors first:rounded-t-md last:rounded-b-md last:border-b-0 hover:bg-muted'
                      >
                        <div className='flex items-center gap-2'>
                          <span>
                            {isLoading ? (
                              <Icons.loader className='size-4 animate-spin' />
                            ) : (
                              pData.count
                            )}
                          </span>
                          <span>{stat.name.toLowerCase()}</span>
                        </div>
                        <div className='pointer-events-none flex items-center opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100'>
                          {Object.keys(pData.filters ?? {}).length > 0 && (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Link
                                  prefetch={false}
                                  className={buttonVariants({
                                    variant: 'ghost',
                                    size: 'icon',
                                  })}
                                  href={createUrl(
                                    pData.modelName,
                                    pData.filters
                                  )}
                                >
                                  <ArrowRightEndOnRectangleIcon className='size-4 scale-105 transition-transform' />
                                </Link>
                              </TooltipTrigger>
                              <TooltipContent>
                                Gå till {stat.name.toLowerCase()}
                              </TooltipContent>
                            </Tooltip>
                          )}

                          {!isWorkEngagementDashboard && (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant='ghost'
                                  size='icon'
                                  onClick={() => onSave(stat)}
                                >
                                  <XMarkIcon className='size-4' />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Ta bort</TooltipContent>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
            {!isLoading &&
              ObjectKeys(groupBySubCategory).map((subCategory) => {
                const subCategoryData = groupBySubCategory[subCategory]?.find(
                  (item) => item.sub_category === subCategory
                );
                const totalCountForSubCategory = data?.reduce((acc, curr) => {
                  const stats = subCategoryData?.statistics;
                  const identifier = stats?.find(
                    (stat) => stat.identifier === curr.identifier
                  );
                  if (
                    curr.identifier === identifier?.identifier &&
                    !identifier?.pinned
                  ) {
                    return acc + (curr.count ?? 0);
                  }
                  return acc;
                }, 0);
                if (subCategoryData?.statistics.every((stat) => stat.pinned))
                  return null;
                return (
                  <div key={subCategory} className='grid gap-2'>
                    <div className='flex items-center justify-between px-0.5'>
                      <div className='flex items-center gap-1'>
                        {getTabIcon(
                          subCategoryTranslationMap[subCategory]?.modelName ??
                            'unset',
                          'text-muted-foreground'
                        )}
                        <span className='text-xs text-muted-foreground'>
                          {subCategoryTranslationMap[subCategory]?.title ??
                            'Unknown'}
                        </span>
                      </div>
                      <span className='text-sm font-medium'>
                        {isLoading ? (
                          <Icons.loader className='size-4 animate-spin' />
                        ) : (
                          totalCountForSubCategory
                        )}
                      </span>
                    </div>
                    <Card className='gap-0 p-0'>
                      {subCategoryData?.statistics.map((stat) => {
                        const subData = data?.filter(
                          (item) => item.identifier !== undefined
                        );
                        const totalCountForIdentifier = subData
                          ?.filter(
                            (item) => item.identifier === stat.identifier
                          )
                          .reduce((acc, curr) => acc + curr.count, 0);
                        const test = subData?.find(
                          (item) => item.identifier === stat.identifier
                        );

                        const name = stat.name;
                        if (stat.pinned) {
                          return null;
                        }
                        if (!test) {
                          return null;
                        }
                        return (
                          <div
                            key={stat.identifier}
                            className='group flex w-full items-center justify-between gap-2 border-b p-2 text-sm transition-colors first:rounded-t-md last:rounded-b-md last:border-b-0 hover:bg-muted'
                          >
                            <div className='flex items-center gap-2'>
                              <span>
                                {isLoading ? (
                                  <Icons.loader className='size-4 animate-spin' />
                                ) : (
                                  totalCountForIdentifier
                                )}
                              </span>
                              <span>{name.toLowerCase()}</span>
                            </div>
                            <div className='pointer-events-none flex items-center opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100'>
                              {Object.keys(test.filters ?? {}).length > 0 && (
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Link
                                      prefetch={false}
                                      className={buttonVariants({
                                        variant: 'ghost',
                                        size: 'icon',
                                      })}
                                      href={createUrl(
                                        test.modelName,
                                        test.filters
                                      )}
                                    >
                                      <ArrowRightEndOnRectangleIcon className='size-4 scale-105 transition-transform' />
                                    </Link>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    Gå till {name.toLowerCase()}
                                  </TooltipContent>
                                </Tooltip>
                              )}
                              {!isWorkEngagementDashboard && (
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <Button
                                      variant='ghost'
                                      size='icon'
                                      onClick={() => onSave(stat)}
                                    >
                                      <PlusIcon className='size-4' />
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    Pinna till toppen
                                  </TooltipContent>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Card>
                  </div>
                );
              })}
          </div>
        </CardContent>
      </Card>
    </>
  );
}
