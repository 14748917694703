import type { ListResponse, Meta, QueryParams } from '@/requests/types';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { getListNested } from '../api/get-list-nested';
import { MAX_PAGE_SIZE } from '../constants';

export async function fetchAllInstances<Instance extends BaseInstance>({
  modelName,
  filters,
  order,
  nested,
  slim,
}: {
  modelName: ModelName;
  filters: QueryParams['filters'];
  order: QueryParams['order'];
  nested?: (keyof Instance)[];
  slim?: boolean;
}) {
  // check how many pages first
  const pageSizeData = await getListNested<Instance>({
    modelName,
    queryParams: {
      filters: {
        ...(filters ?? {}),
      },
      page: 1,
      pageSize: 1,
      order,
      slim,
    },
    nested,
  });

  const totalSize = pageSizeData.meta.total_amount;

  const pageAmount = totalSize < 500 ? 1 : Math.ceil(totalSize / 500);

  const promises: Promise<{
    list: BaseInstance[];
    meta: Meta;
  }>[] = [];

  for (let i = 1; i <= pageAmount; i++) {
    promises.push(
      getListNested<Instance>({
        modelName,
        queryParams: {
          filters: {
            ...(filters ?? {}),
          },
          page: i,
          pageSize: MAX_PAGE_SIZE,
          order,
          slim,
        },
        nested,
      })
    );
  }

  const result = await Promise.allSettled(promises);

  const succeededResults = (
    result as unknown as {
      value: ListResponse<Instance>;
      status: 'fulfilled' | 'rejected';
    }[]
  ).filter((r) => r.status === 'fulfilled');

  return {
    list: succeededResults.map((r) => r.value.list).flat(),
    meta: { ...pageSizeData.meta, page_amount: pageSizeData.meta.total_amount },
  };
}
