import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label, labelVariants } from '@/components/ui/label';
import { TextArea } from '@/components/ui/textarea';
import { cn } from '@/lib/cn';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import type { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type { DashBoardNote, WorkEngagementDashboard } from '../types';
import { type DashBoard } from '../types';

const cleanNote = {
  title: '',
  note_content: '',
  archived: false,
  pinned: false,
  color: '',
  created_at: '',
};

export function DashboardNotesModal({
  dashboard,
  noteIndex,
  setNoteIndex,
  open,
  setOpen,
}: {
  dashboard: DashBoard | WorkEngagementDashboard;
  noteIndex: number | null;
  setNoteIndex: Dispatch<SetStateAction<number | null>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { mutateAsync, isPending } = useUpdateDashboard();
  const queryClient = useQueryClient();
  const note = noteIndex != null ? dashboard?.notes?.[noteIndex] : undefined;
  const form = useForm<DashBoardNote>({
    defaultValues: cleanNote,
    values: note ?? cleanNote,
  });

  const closeAndReset = () => {
    form.reset();
    setOpen(false);
    setNoteIndex(null);
  };
  const { isDirty } = form.formState;

  const onSubmit = () => {
    const data = form.getValues();
    const clonedDashboard = cloneDeep(dashboard);
    if (!data.title) {
      data.title = data.note_content.substring(0, 30);
    }
    if (noteIndex !== null) {
      clonedDashboard.notes[noteIndex] = data;
    } else {
      clonedDashboard.notes.push(data);
    }
    if (data.color === '') {
      data.color = null;
    }
    if (data.created_at === '') {
      data.created_at = DateTime.now().toISO();
    }
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Sparar...',
      success: () => {
        closeAndReset();
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        return 'Anteckning sparad';
      },
      error: () => {
        return 'Anteckning kunde ej sparas';
      },
    });
  };
  const { errors } = form.formState;

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        form.reset();
      }}
    >
      <DialogContent
        size='md'
        className='p-0'
        onInteractOutside={closeAndReset}
      >
        <DialogHeader>
          <DialogTitle>
            {noteIndex !== null
              ? `Redigera anteckning ${dashboard?.notes?.[noteIndex]?.title}`
              : 'Skapa anteckning'}
          </DialogTitle>
          <Button variant='secondary' size={'icon-sm'} onClick={closeAndReset}>
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className='grid gap-4 p-4'>
              <div className='grid gap-2'>
                <Label htmlFor='title'>Titel</Label>
                <Input
                  id='title'
                  placeholder='Titel'
                  {...form.register('title')}
                />
              </div>
              <div className='grid gap-2'>
                <Label
                  htmlFor='content'
                  className={cn(errors?.note_content && 'text-destructive')}
                >
                  Innehåll *
                </Label>
                <TextArea
                  id='content'
                  placeholder='Skriv en anteckning...'
                  rows={4}
                  {...form.register('note_content', {
                    required: 'Vänligen fyll i innehåll',
                    maxLength: {
                      value: 255,
                      message: 'Fältet får inte vara längre än 255 tecken',
                    },
                  })}
                />
                {!errors?.note_content && (
                  <p className='text-xs text-muted-foreground'>
                    (Max 255 tecken)
                  </p>
                )}
                {errors?.note_content && (
                  <p
                    className={cn(
                      labelVariants(),
                      'text-sm font-medium text-destructive'
                    )}
                  >
                    {errors.note_content.message}, det är nu{' '}
                    {form.getValues().note_content.length} tecken
                  </p>
                )}
              </div>
            </div>

            <DialogFooter>
              <Button disabled={!isDirty || isPending} type='submit'>
                {noteIndex !== null ? 'Spara' : 'Skapa'}
              </Button>
              <Button
                type='button'
                variant='outline'
                onClick={() => {
                  form.reset();
                  setOpen(false);
                }}
              >
                Avbryt
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
